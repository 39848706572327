
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import HomePage from "./components/HomePage";
// import AboutPage from "./components/About/AboutPage";
// import Services from "./components/services/Services";

// import ContactPage from "./components/Contact/ContactPage";
import { lazy, Suspense } from "react";
import Loader from "./components/Loader";

const HomePage=lazy(()=>import('./components/HomePage'))
const Services=lazy(()=>import('./components/services/Services'))
const ContactPage=lazy(()=>import('./components/Contact/ContactPage'))
const AboutPage=lazy(()=>import('./components/About/AboutPage'))
const GalleryPage=lazy(()=>import('./components/Galley/Banner'))

function App() {
  return (
    <h1>
      <BrowserRouter>

      <Suspense fallback={<Loader/>}>
      <Routes>

        <Route path="/" element={<HomePage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/gallery" element={<GalleryPage/>}/>



      </Routes>
        </Suspense>
      </BrowserRouter>

  </h1>
  );
}

export default App;
